<template>
    <div>
        <div class="second_return_icon" @click="returnButton()">
            <i class="iconfont icon-fanhui" ></i>
            客户详情
        </div>
        <Company></Company>
    </div>
</template>
<script>
import Company from '../../supplier/components/companyDetail.vue'
export default {
    name:'detail',
    components:{Company},
    data(){
        return{
            
        }
    },
  methods: {
        returnButton(){
            this.$router.push({ path: '/system/coreEnterprise/myClient' })
        },
    }
  
}
</script>
<style scoped>
.second_return_icon{
    margin-left: 20px;
    cursor: pointer;
}
.second_return_icon i{
    margin-right: 24px;
    font-size: 20px;
}
</style>